import React from 'react'
import TableData from '../components/TableData'

export default function Wouters() {
  return (
    <>
      <TableData tak='wouter' />
    </>
  )
}
