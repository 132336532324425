import React from 'react'
import TableData from '../components/TableData'

export default function Jonggivers() {
  return (
    <>
      <TableData tak='jonggiver' />
    </>
  )
}
