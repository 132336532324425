import React from 'react';
import TableData from '../components/TableData';

export default function Givers() {
  return (
    <>
      <TableData tak='giver' />
    </>
  )
}
