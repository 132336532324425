import React from 'react';
import TableData from '../components/TableData';

export default function Jins() {
  return (
    <>
      <TableData tak='jin' />
    </>
  )
}
